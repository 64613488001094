<template>
  <div class="max-w-3xl mt-3 flex flex-col gap-2">
    <div class="flex gap-2">
      <b-btn
        variant="primary"
        size="sm"
        @click="createAccount()"
      >
        Create account
      </b-btn>
    </div>
    <div>
      <table
        class="table table-google table-highlight"
        :class="{ 'table-busy': isLoading }"
      >
        <thead>
          <tr>
            <th class="text-nowrap row-fit">ID</th>
            <th class="text-nowrap">Name</th>
            <th class="text-nowrap">Login</th>
            <th class="text-nowrap row-fit">Admin</th>
            <th class="text-nowrap row-fit">Last Active</th>
            <th class="text-nowrap row-fit">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="account in accounts" :key="account.id">
            <td>{{ account.id }}</td>
            <td>{{ account.display_name }}</td>
            <td>
              <span :class="{ 'text-danger font-bold': account.blocked }">
                {{ account.login }}
              </span>
            </td>
            <td class="text-center">
              <i v-if="account.is_admin" class="fas fa-user-shield"></i>
            </td>
            <td class="text-right">
              <span v-if="account.last_activity === 0">
                Never
              </span>
              <span v-else class="text-nowrap">
                {{ account.last_activity | longdate }} {{ account.last_activity | time }}
              </span>
            </td>
            <td>
              <div>
                 <b-dropdown right text="Action" variant="primary" size="sm">
                  <b-dropdown-item @click="editDetails(account)">Edit details</b-dropdown-item>
                  <b-dropdown-item @click="editRoles(account)">Edit roles</b-dropdown-item>
                  <b-dropdown-item @click="deleteAccount(account)">Delete account</b-dropdown-item>
                </b-dropdown>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <account-details
      v-if="modals.details.showModal"
      :data="modals.details"
      :id="accountId"
      @updated="fetchAccounts()"
    />

    <account-roles
      v-if="modals.roles.showModal"
      :data="modals.roles"
      :id="accountId"
      @updated="fetchAccounts()"
    />
  </div>
</template>

<script>
const AccountDetails = () => import('@/components/admin/AccountDetails.vue');
const AccountRoles = () => import('@/components/admin/AccountRoles.vue');

export default {
  name: 'Roles',
  components: {
    AccountDetails,
    AccountRoles,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
      accounts: [],
      accountId: null,
      modals: {
        details: {
          showModal: false,
        },
        roles: {
          showModal: false,
        },
      },
    };
  },
  methods: {
    fetchAccounts() {
      this.loadingCount++;
      this.$http
        .get('/accounts')
        .then((res) => {
          this.accounts = res.body;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch accounts: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    editDetails(editAccount) {
      this.accountId = editAccount.id;
      this.modals.details.showModal = true;
    },
    editRoles(editAccount) {
      this.accountId = editAccount.id;
      this.modals.roles.showModal = true;
    },
    createAccount() {
      this.accountId = null;
      this.modals.details.showModal = true;
    },
    deleteAccount(deleteAccount) {
      if (!confirm(`Do you really wish to delete ${deleteAccount.login}?`)) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/accounts/${deleteAccount.id}`)
        .then(() => {
          this.accounts = this.accounts.filter((account) => account.id !== deleteAccount.id);
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete account: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
  created() {
    this.fetchAccounts();
  },
};
</script>

<style lang="scss" scoped>
</style>
